import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";

import SecondaryButton from "../Button/SecondaryButton";

import PhotographySlide from "./SlideLayouts/PhotographySlide";
import PortfolioCard from "./SlideLayouts/PortfolioCard";
import TestimonialSlide from "./SlideLayouts/TestimonialSlide";

import NextCircleButton from "./Buttons/NextCircleButton";
import PrevCircleButton from "./Buttons/PrevCircleButton";

import {
  EmblaContainer,
  StyledEmbla,
  Viewport,
  LinkButtonContainer,
  SliderButtonContainer
} from "./style";


const Carousel = ({
  layout,
  options,
  nodes,
  service,
  linkLocation,
  linkText,
  buttonsEnabled
}) => {
  const [viewportRef, embla] = useEmblaCarousel(options);
  const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    setPrevButtonEnabled(embla.canScrollPrev());
    setNextButtonEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;

    embla.on("select", onSelect);
    
    onSelect();
  }, [embla, onSelect]);

  return (
    <StyledEmbla>
      <Viewport
        ref={ viewportRef }
      >
        <EmblaContainer>
          { nodes.map((node) => {
            switch (layout) {
              case 'photo':
                return <PhotographySlide node={ node.node } />;
              case 'portfolio':
                return <PortfolioCard node={ node.node } service={ service } />;
              case 'testimonial':
                return <TestimonialSlide node={ node.node } />;
              default:
                return null;
            }
          })}
        </EmblaContainer>
      </Viewport>
      { buttonsEnabled &&
        <SliderButtonContainer>
          <PrevCircleButton onClick={ scrollPrev } enabled={ prevButtonEnabled } />
          <NextCircleButton onClick={ scrollNext } enabled={ nextButtonEnabled } />
        </SliderButtonContainer>
      }
      { linkLocation &&
        <LinkButtonContainer>
          <SecondaryButton
            linkLocation={ linkLocation }
            linkText={ linkText }
          />
        </LinkButtonContainer>
      }
    </StyledEmbla>
  )
}


export default Carousel;
