import styled from "styled-components";

import {
  CONTAINERS
} from "../../theme/style-constants";


export const StyledEmbla = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 0 0 1rem;

  &.is-draggable {
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
`;

export const EmblaContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const SliderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: ${ CONTAINERS.largeDesktop };
  margin: 2rem auto 0;
`;

export const LinkButtonContainer = styled.div`
  margin: 2rem 0 0;
  text-align: center;
`;
