import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  COLORS,
  SCREENS
} from "../../../../theme/style-constants";


export const StyledCard = styled.div`
  position: relative;
  flex: 0 0 auto;
  margin: 0 1rem;
  width: 300px;
  box-shadow: 0 4px 8px 0 ${ COLORS.background.dark }22, 0 6px 20px 0 ${ COLORS.background.dark }11;

  &:focus {
    outline-style: none;
    outline-width: 0;
  }

  @media (min-width: ${ SCREENS.tablet }) {
    margin: 0 2rem;
    width: 600px;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: 800px;
  }
`;

export const StyledCardImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  z-index: 50;
`;

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000AA;
  color: ${ COLORS.text.light };
  opacity: 0;
  z-index: 100;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export const OverlayContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
