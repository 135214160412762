import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  COLORS,
  SCREENS
} from "../../../../theme/style-constants";


export const StyledSlide = styled.div`
  position: relative;
  flex: 0 0 auto;
  margin: 0 1rem;
  width: 300px;

  &:focus {
    outline-style: none;
    outline-width: 0;
  }

  @media (min-width: ${ SCREENS.tablet }) {
    margin: 0 2rem;
    width: 600px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 300px;
  height: calc(300px / 1.803);
  box-shadow: 0 4px 8px 0 ${ COLORS.background.dark }22, 0 6px 20px 0 ${ COLORS.background.dark }11;

  @media (min-width: ${ SCREENS.tablet }) {
    width: 600px;
    height: calc(600px / 1.803);
  }
`;

export const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  z-index: 50;
`;

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000088;
  color: ${ COLORS.text.light };
  opacity: 0;
  z-index: 100;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export const OverlayContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StyledLogo = styled(GatsbyImage)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: auto;
  transform: translate(-50%, -50%);

  @media (min-width: ${ SCREENS.tablet }) {
    width: 150px;
  }
`;

export const DetailsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const StyledPosition = styled.p`
  margin: 0;
  padding: 0 1rem;
  font-size: 0.9rem;
  font-weight: 100;

  @media (min-width: ${ SCREENS.tablet }) {
    padding: 0 1rem;
    font-size: 1rem;
  }
`;

export const StyledPhotographer = styled.p`
  margin: 0;
  padding: 0 1rem 0.5rem;
  font-size: 0.9rem;
  font-weight: 100;

  @media (min-width: ${ SCREENS.tablet }) {
    padding: 0 1rem 1rem;
    font-size: 1rem;
  }
`;

export const StyledPhotoHeading = styled.h3`
  margin: 0;
  padding: 1rem 0;
  font-size: 1.5rem;
`;
