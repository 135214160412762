import styled from "styled-components";

import CarouselCircleNext from "../../../../assets/svg/icons/carousel/CarouselCircleNext.svg";


export const StyledButton = styled.button`
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  line-height: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const Icon = styled(CarouselCircleNext)`
  width: 3.5rem;
  height: 3.5rem;
`;
