import * as React from "react";

import {
  Icon,
  StyledButton
} from "./style";


const NextCircleButton = ({
  ...props
})=> {
  return (
    <StyledButton
      { ...props }
    >
      <Icon />
    </StyledButton>
  )
}


export default NextCircleButton;
