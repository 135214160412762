import * as React from "react";
import { getImage } from "gatsby-plugin-image";

import {
  DetailsContainer,
  ImageContainer,
  OverlayContainer,
  StyledImage,
  StyledLogo,
  StyledOverlay,
  StyledPhotographer,
  StyledPosition,
  StyledPhotoHeading,
  StyledSlide
} from "./style";


const PhotographySlide = ({
  node
}) => {
  return (
    <StyledSlide>
      <ImageContainer>
        <StyledImage
          image={ getImage(node.frontmatter.image) }
        />
        <StyledOverlay>
          <OverlayContainer>
            <StyledLogo
              image={ getImage(node.frontmatter.logo) }
            />
            <DetailsContainer>
              <StyledPosition>Lead Photographer</StyledPosition>
              <StyledPhotographer>{ node.frontmatter.photographer }</StyledPhotographer>
            </DetailsContainer>
          </OverlayContainer>
        </StyledOverlay>
      </ImageContainer>
      <StyledPhotoHeading>{ node.frontmatter.title }</StyledPhotoHeading>
    </StyledSlide>
  )
}


export default PhotographySlide;
