import * as React from "react";
import { getImage } from "gatsby-plugin-image";

import SecondaryButton from "../../../Button/SecondaryButton";

import {
  ButtonContainer,
  OverlayContainer,
  StyledCard,
  StyledCardImage,
  StyledOverlay
} from "./style";


const PortfolioCard = ({
  service,
  node
}) => {
  switch (service) {
    case 'brand':
      if (node.frontmatter.brandingCard) {
        return (
          <StyledCard>
            <StyledCardImage
              image={ getImage(node.frontmatter.brandingCard) }
            />
          </StyledCard>
        );
      } else {
        return null;
      }
    case 'content':
      if (node.frontmatter.contentCard) {
        return (
          <StyledCard>
            <StyledCardImage
              image={ getImage(node.frontmatter.contentCard) }
            />
          </StyledCard>
        );
      } else {
        return null;
      }
    case 'marketing':
      if (node.frontmatter.marketingCard) {
        return (
          <StyledCard>
            <StyledCardImage
              image={ getImage(node.frontmatter.marketingCard) }
            />
          </StyledCard>
        );
      } else {
        return null;
      }
    case 'web':
      if (node.frontmatter.webCard) {
        return (
          <StyledCard>
            <StyledCardImage
              image={ getImage(node.frontmatter.webCard) }
            />
            <StyledOverlay>
              <OverlayContainer>
                <ButtonContainer>
                  <SecondaryButton
                    isExternal
                    linkLocation={ node.frontmatter.webLink }
                    linkText="View Website"
                    darkBackground
                  />
                </ButtonContainer>
              </OverlayContainer>
            </StyledOverlay>
          </StyledCard>
        );
      } else {
        return null;
      }
    case 'home':
      if (node.frontmatter.homeCard) {
        return (
          <StyledCard>
            <StyledCardImage
              image={ getImage(node.frontmatter.homeCard) }
            />
          </StyledCard>
        );
      } else {
        return null;
      }
    default:
      return null;
  }
}


export default PortfolioCard;
