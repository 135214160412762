import * as React from "react";
import { getImage } from "gatsby-plugin-image";

import {
  StyledAuthorHeading,
  StyledClutchLogo,
  StyledCompanyHeading,
  StyledSlide,
  StyledTestimonialText,
  StyledVerifiedReview
} from "./style";


const TestimonialSlide = ({
  node
}) => {
  return (
    <StyledSlide>
      <div>
        <StyledCompanyHeading>
          { node.frontmatter.company }
        </StyledCompanyHeading>
        <StyledAuthorHeading>
          { node.frontmatter.reviewer }
        </StyledAuthorHeading>
      </div>
      <StyledTestimonialText>
        { node.frontmatter.testimonial }
      </StyledTestimonialText>
      <div>
        <StyledClutchLogo
          image={ getImage(node.frontmatter.image) }
        />
        <StyledVerifiedReview>
          Verified Review
        </StyledVerifiedReview>
      </div>
    </StyledSlide>
  )
}


export default TestimonialSlide;
