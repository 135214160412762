import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../../../theme/style-constants";


export const StyledSlide = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${ CONTAINERS.largeDesktop };
  width: 100%;
  height: 32rem;
  border-bottom: 1px solid ${ COLORS.border.dark };

  &:focus {
    outline-style: none;
    outline-width: 0;
  }

  @media (min-width: ${ SCREENS.tablet }) {
    height: 28rem;
  }
  
  @media (min-width: ${ SCREENS.laptop }) {
    height: 22rem;
  }
`;

export const StyledCompanyHeading = styled.h3`
  font-size: 1.6rem;
  margin: 0 0 0.5rem;
`;

export const StyledAuthorHeading = styled.h6`
  margin: 0;
`;

export const StyledTestimonialText = styled.p`
  max-width: calc(${ CONTAINERS.largeDesktop } * 0.7);
  width: calc(100% - 2rem);
  margin: 2rem 0 3rem;

  @media (min-width: ${ SCREENS.tablet }) {
    width: calc(100% - 6rem);
  }
`;

export const StyledClutchLogo = styled(GatsbyImage)`
  width: 11rem;
  height: auto;
`;

export const StyledVerifiedReview = styled.p`
  margin: 1rem 0;
`;
